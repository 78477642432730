import ProductGridItem from "./ProductGridItem";

export default function ProductGrid({ products }) {
  return products.map((product) => (
    <div
      key={product.id}
      className="col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-2 mb-4"
    >
      <div>
        <ProductGridItem product={product} />
      </div>
    </div>
  ));
}
